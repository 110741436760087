import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-privacy-consent',
  templateUrl: './privacy-consent.component.html',
  styleUrls: ['./privacy-consent.component.scss']
})
export class PrivacyConsentComponent implements OnInit {

  constructor(public modalRef: BsModalRef) { }
  ngOnInit(): void {
  }

  save() {
    localStorage.setItem('cookie_consent_calculator', 'true');
    this.modalRef.hide();
  }

}
