<div class="modal-header privacy">
    <h4 class="modal-title pull-left">We care about your privacy</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
       <span aria-hidden="true">×</span>
    </button>
 </div>
 <div class="modal-body privacy">
     <p>
        <strong>eco-shaper</strong> is committed to protecting your privacy. You can visit most pages on our website without giving us any information about yourself. But sometimes we do need information to provide services that you request. We want you to know that we keep all of your private data secure and only share aggregate numbers of employees’ carbon footprints for company reporting. <strong>We never share private information.</strong>
     </p>
    <p>
        If you have any questions or concerns relating to eco-shaper’s carbon calculator or any aspect of our website or would like to find out more about how we collect, store or use your personal data, please contact <a href="mailto:neil@eco-shaper.com">neil&#64;eco-shaper.com</a> acting as our Data Protection Officer.
    </p>
  </div>
 <div class="modal-footer privacy">
   <p class="btn p-0" (click)="save()"><input class="btn mr-2" type="checkbox">Accept and continue</p>
 </div>